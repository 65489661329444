<template>
    <v-container fluid>
        <v-card :loading="loading" class="mt-3">
            <Page-Header
                :title="$t('school.semesters')"
                icon="mdi-account-group"
            >
                <template v-slot:buttons>
                    <v-btn
                        :loading="loading"
                        color="primary darken-1"
                        @click.native="dialog = true"
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t("add") }}
                    </v-btn>
                </template>
            </Page-Header>
            <v-data-table
                :headers="headers"
                class="pa-5"
                :items="semesters"
                :search="search"
                :loading="loading"
                :loading-text="$t('loading')"
                hide-default-footer
            >
                <template v-slot:[`item.billDate`]="{ item }">
                    {{ item.billDate | moment("yyyy-MM-DD") }}
                </template>
                <template v-slot:[`item.billType`]="{ item }">
                    {{ billTypes[item.billType] }}
                </template>
                <template v-slot:[`item.payType`]="{ item }">
                    {{ payTypes[item.payType] }}
                </template>
                <template v-slot:[`item.billSetting.priceType`]="{ item }">
                    {{ priceTypes[item.billSetting.priceType] }}
                </template>
                <template v-slot:top>
                    <!--Search bar-->
                    <v-text-field
                        dense
                        outlined
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('search')"
                        single-line
                        hide-details
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <!--Save popup-->
                    <v-dialog v-model="dialog" persistent max-width="600px">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-card>
                                <v-toolbar color="primary darken-1" dark>
                                    <v-card-title>
                                        <span class="">{{
                                            (editedIndex === -1
                                                ? $t("add")
                                                : $t("edit")) +
                                                " " +
                                                $t("school.semester")
                                        }}</span>
                                    </v-card-title>
                                </v-toolbar>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" md="12">
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    hide-details
                                                    @keyup.enter="save"
                                                    persistent-hint
                                                    v-model="
                                                        editedItem.semesterName
                                                    "
                                                    :rules="rules"
                                                    :label="
                                                        $t(
                                                            'school.semesterName'
                                                        )
                                                    "
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        color="primary darken-1 white--text"
                                        @click="save"
                                        :disabled="!valid"
                                        :min-width="100"
                                    >
                                        <v-icon
                                            >mdi-content-save-outline</v-icon
                                        >
                                        {{ $t("save") }}
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="red" @click="close">
                                        {{ $t("cancel") }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                    <!--Delete popup-->
                    <confirm-dialog
                        :openDialog="dialogDelete"
                        :onClicked="deleteItemConfirm"
                        :onClose="closeDelete"
                        toolBarColor="red darken-2"
                    ></confirm-dialog>
                </template>
                <template v-slot:[`item.isMain`]="{ item }">
                    <v-switch
                        v-model="item.isMain"
                        class="pa-3"
                        readonly
                    ></v-switch>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-row no-gutters>
                        <v-col cols="6"
                            ><v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-if="isInRole(47)"
                                        class="mr-2"
                                        @click="editItem(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </template>
                                <span> {{ $t("edit") }} </span>
                            </v-tooltip></v-col
                        >
                        <v-col cols="6"
                            ><v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-if="isInRole(48)"
                                        color="red"
                                        class="mx-3"
                                        @click="deleteItem(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-delete-outline
                                    </v-icon>
                                </template>
                                <span> {{ $t("delete") }}</span>
                            </v-tooltip></v-col
                        >
                    </v-row>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";

export default {
    components: { ConfirmDialog, PageHeader },
    data() {
        return {
            valid: true,
            loading: true,
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            search: "",
            headers: [
                {
                    text: this.$t("school.semesterName"),
                    value: "semesterName"
                },
                { text: "", value: "actions" }
            ],
            semesters: [],
            editedItem: {
                semesterGuid: "",
                semesterName: ""
            },
            defaultItem: {
                semesterGuid: "",
                semesterName: ""
            },

            rules: [value => !!value || this.$t("required")]
        };
    },
    created() {
        if (this.isInRole(46)) {
            this.loading = true;
            this.refreshTable();
        } else this.redirectUnauthorizedUsers();
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },
    methods: {
        editItem(item) {
            this.editedIndex = this.semesters.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.semesters.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            var deleteItem = this.semesters[this.editedIndex];
            axios
                .delete("Students/DeleteSemester?id=" + deleteItem.semesterGuid)
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                    this.refreshTable();
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    this.refreshTable();
                    console.log(e);
                });

            this.closeDelete();
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        refreshTable() {
            axios
                .get("Students/GetSemesters")
                .then(response => {
                    this.semesters = response.data.data;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        save() {
            if (this.$refs.form.validate()) {
                if (this.editedIndex == -1) {
                    this.editedItem.semesterGuid =
                        "00000000-0000-0000-0000-000000000000";
                }
                axios
                    .post("Students/SaveSemester", this.editedItem)
                    .then(response => {
                        if (response.data.status == "Successful") {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                            this.close();
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                        this.refreshTable();
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        this.refreshTable();
                        console.log(e);
                    });
            }
        }
    }
};
</script>
